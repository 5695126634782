import { Vue, Component, Prop } from "vue-property-decorator";

import PartnerTile from "../PartnerTile/PartnerTile.vue";
import { Partner } from "@/types/partner";

@Component({
  components: {
    PartnerTile,
  },
})
export default class PartnersList extends Vue {
  @Prop({ type: Array, required: true }) private readonly items!: Partner[];
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
}
