import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Rating extends Vue {
  @Prop({ type: Number, required: true }) private readonly value!: number;
  @Prop({ type: Number, default: 24 }) private readonly size!: number;
  @Prop({ type: Boolean, default: false }) private readonly readonly!: boolean;

  private get buttonSize() {
    return this.size + 10;
  }
}
