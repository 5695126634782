var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('InfiniteList',{attrs:{"loading":_vm.loading,"items":_vm.items,"items-per-page":2},scopedSlots:_vm._u([{key:"skeleton-loader",fn:function({ itemsPerPage }){return _vm._l((itemsPerPage),function(i){return _c('v-skeleton-loader',{key:i,class:{
          'mt-6': !_vm.$vuetify.breakpoint.mobile && i > 1,
          'mt-4': _vm.$vuetify.breakpoint.mobile && i > 1,
        },attrs:{"type":"image, card-heading@2"}})})}},{key:"items",fn:function({ items }){return _vm._l((items),function({
            id,
            logo,
            title,
            content,
            rating,
            buttonText,
            promoCode,
            promoCodeText,
            link,
          },itemIndex){return _c('PartnerTile',{key:id,class:{
          'mt-6': !_vm.$vuetify.breakpoint.mobile && itemIndex > 0,
          'mt-4': _vm.$vuetify.breakpoint.mobile && itemIndex > 0,
        },attrs:{"title":title,"content":content,"link":link,"logo":logo,"rating":rating,"order":itemIndex + 1,"button-text":buttonText,"promo-code":promoCode,"promo-code-text":promoCodeText}})})}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }