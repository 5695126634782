import { Vue, Component, Prop } from "vue-property-decorator";
import {
  copyTextClipboard,
  decodeHtmlEntities,
  getPartnerLogo,
} from "@helpers";

import Rating from "../Rating/Rating.vue";

@Component({
  components: {
    Rating,
  },
})
export default class PartnerTile extends Vue {
  @Prop({ type: String, required: true }) private readonly logo!: string;
  @Prop({ type: String, required: true }) private readonly title!: string;
  @Prop({ type: String, required: true }) private readonly content!: string;
  @Prop({ type: Number, required: true }) private readonly rating!: number;
  @Prop({ type: Number, required: true }) private readonly order!: number;
  @Prop({ type: String }) private readonly link?: string;
  @Prop({ type: String }) private readonly buttonText?: string;
  @Prop({ type: String }) private readonly promoCode?: string;
  @Prop({ type: String })
  private readonly promoCodeText?: string;

  private get partnerLink() {
    if (!this.link) return;

    return decodeHtmlEntities(this.link);
  }

  private get logoUrl() {
    return getPartnerLogo(this.logo);
  }

  private onClickPromocode() {
    if (!this.promoCode) return;

    copyTextClipboard(this.promoCode);

    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }
}
